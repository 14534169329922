:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

body {
  /* color: rgb(var(--foreground-rgb)); */
  /* background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb)); */
  background-color: #f7f7fa !important;
  color: #595959;
  padding: 0;
  margin: 0;
}

.root {
  background-color: #f7f7fa;
}
#error-page {
  margin-top: auto;
  width: 100%;
  text-align: center;
  margin-top: 40vh;
}
.blackText {
  color: rgba(0, 0, 0, 0.54) !important;
}
.blackText p {
  color: rgba(0, 0, 0, 0.54);
}
.paddingSelect > div {
  padding-bottom: 4px;
  padding-top: 4px;
}

.image-upload > input {
  display: none;
}

.image-upload img {
  width: 80px;
  cursor: pointer;
}

.contenedorLoginImg {
  background: url("../img/login.png"),
    lightgray -426.737px 18.253px / 172.315% 98.673%;
  background-repeat: no-repeat;
  background-color: #f7f7fa;
  background-position: right;
  min-height: 102.2vh;
  width: 100%;
  height: 100%;
}
.fontSizeTimer {
  font-size: 50px !important;
}
.fontSizeText {
  font-size: 20px;
}
.active .containerMenuText {
  background-color: #d0d0d0;
}
.containerTimer {
  /* background-color: rgb(250, 250, 250); */
  display: flex;
  width: fit-content;
  /* border-radius: 16px; */
  /* padding: 20px; */
  margin: auto;
  max-width: 100%;
}
.containerTimer div {
  padding: 40px;
  text-align: center;
}
.cardEventdetailFirst {
  display: flex;
  justify-content: space-between;
  align-items: start;
  width: 100%;
}
.cardEventdetailSecondMore {
  display: flex;
  justify-content: flex-start;
  align-items: start;
  width: 100%;
  margin-top: 10px;
}

.hiddenText {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.containerMenuConfig > div,
.containerMenuConfig > button {
  margin-right: 10px;
}
.containerButtonSponsors {
  display: flex;
  justify-content: end;
  align-items: end;
  text-align: right;
}

.buttonIconShare {
  border: 0.5px solid rgb(198, 198, 198) !important;
  border-radius: 10px !important;
  color: rgb(117, 117, 117) !important;
  padding: 5px 5px !important;
  margin-right: 6px !important;
}
.containerCardAgenda {
  border: 1.4px solid #221b3a;
  border-radius: 25px;
  padding: 20px;
}

.contentImgPreviewHeader {
  width: 100%;
  min-height: 350px;
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  position: absolute;
  top: 0;
  z-index: -6;
}
.widthContextAdd {
  width: 820px;
}

.widthMiniTable {
  width: 50%;
  min-width: 240px;
}

@media (max-width: 820px) {
  .widthContextAdd {
    width: 100%;
  }
}

@media (min-width: 1116px) {
  .d-none-menuConfigIcon {
    display: none !important;
  }
}

@media (max-width: 1116px) {
  .containerMenuConfig {
    display: grid;
    /* flex-direction: column; */
    padding: 5px;
    padding-top: 0;
    justify-content: center;
    align-items: center;
  }
  .containerMenuConfig > div,
  .containerMenuConfig > button {
    margin-top: 8px;
    margin-right: 0 !important;
    width: 100%;
  }
  .d-none-menuConfig {
    display: none;
  }
  .containerButtonSponsors {
    display: initial;
  }
  .widthMiniTable {
    width: 100%;
  }
}

@media (max-width: 700px) {
  .fontSizeTimer {
    font-size: 22px;
  }
  .fontSizeText {
    font-size: 14px;
  }
  .containerButtonSponsors button {
    width: 100%;
  }
}
@media (max-width: 430px) {
  .imgIcon {
    max-width: 100%;
    height: auto;
  }
  .containerPage {
    padding: 0;
  }
  .fontSizeTimer {
    font-size: 20px;
  }
  .fontSizeText {
    font-size: 12px;
  }

  .containerTimer div {
    padding: 10px;
    text-align: center;
  }
}

/*
!!!!
This pen is being refactored
!!!!
*/

/*
=====
DEPENDENCES
=====
*/

/*
text component
*/

/*
extensions
*/

.time__month {
  margin-left: var(--timelineMounthMarginLeft, 0.25em);
}

/*
skin
*/

.time {
  padding: var(--timePadding, 0.25rem 1.25rem 0.25rem);
  background-color: var(--timeBackgroundColor, #f0f0f0);
  text-align: center;
  text-transform: var(--timeTextTransform, uppercase);
  color: black;
  max-width: 100px;
  border-radius: 0 30px 30px 0;
  color: white;
}

/*
card component
*/

/*
core styles
*/

.card {
  padding: var(--timelineCardPadding, 1.5rem 1.5rem 1.25rem);
}

.card__content {
  margin-top: var(--cardContentMarginTop, 0.5rem);
}

/*
skin
*/

.card {
  border-radius: var(--timelineCardBorderRadius, 2px);
  border-left: var(--timelineCardBorderLeftWidth, 3px) solid
    var(--timelineCardBorderLeftColor, var(--uiTimelineMainColor));
  box-shadow: var(
    --timelineCardBoxShadow,
    0 1px 3px 0 rgba(0, 0, 0, 0.12),
    0 1px 2px 0 rgba(0, 0, 0, 0.24)
  );
  background-color: var(--timelineCardBackgroundColor, #fff);
}

/*
extensions
*/

.card__title {
  --rTitleMarginTop: var(--cardTitleMarginTop, 1rem);
  font-size: var(--cardTitleFontSize, 1.25rem);
}

/*
=====
CORE STYLES
=====
*/

.timeline {
  display: var(--timelineDisplay, grid);
  grid-row-gap: var(--timelineGroupsGap, 2rem);
}

.timeline__year {
  margin-bottom: 1.25rem; /* 1 */
}

.timeline__cards {
  display: var(--timeloneCardsDisplay, grid);
  grid-row-gap: var(--timeloneCardsGap, 1.5rem);
}

/*
=====
SKIN
=====
*/

.timeline {
  --uiTimelineMainColor: var(--timelineMainColor, #222);
  --uiTimelineSecondaryColor: var(--timelineSecondaryColor, #fff);

  border-left: var(--timelineLineWidth, 3px) solid
    var(--timelineLineBackgroundColor, var(--uiTimelineMainColor));
  padding-top: 1rem;
  padding-bottom: 1.5rem;
}

.timeline__year {
  --timePadding: var(--timelineYearPadding, 0.5rem 1.5rem);
  --timeColor: var(--uiTimelineSecondaryColor);
  --timeBackgroundColor: var(--uiTimelineMainColor);
  --timeFontWeight: var(--timelineYearFontWeight, 400);
}

.timeline__card {
  position: relative;
  margin-left: var(--timelineCardLineGap, 1rem);
}

/*
1. Stoping cut box shadow
*/

.timeline__cards {
  overflow: hidden;
  padding-top: 0.25rem; /* 1 */
  padding-bottom: 0.25rem; /* 1 */
}

.timeline__card::before {
  content: "";
  width: 100%;
  height: var(--timelineCardLineWidth, 2px);
  background-color: var(
    --timelineCardLineBackgroundColor,
    var(--uiTimelineMainColor)
  );

  position: absolute;
  top: var(--timelineCardLineTop, 1rem);
  left: -50%;
  z-index: -1;
}

/*
=====
SETTINGS
=====
*/
/**/
.timeline {
  --timelineMainColor: #2f557f;
}

/*
=====
DEMO
=====
*/

.page {
  max-width: 100%;
  padding: 2rem 0 3rem 1rem;
  margin-left: auto;
  margin-right: auto;
}

.substack {
  width: 100%;
  max-width: 100%;
  height: 280px;
  margin: 1rem auto;
}

.linktr {
  display: flex;
  justify-content: flex-end;
  padding: 2rem;
  text-align: center;
}

.linktr__goal {
  background-color: rgb(209, 246, 255);
  color: rgb(8, 49, 112);
  box-shadow: rgb(8 49 112 / 24%) 0px 2px 8px 0px;
  border-radius: 2rem;
  padding: 0.75rem 1.5rem;
}
